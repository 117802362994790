import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { User } from '../user/user';
import { NotificationProvider } from '../notification/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(
    private router: Router,
    private user: User,
    private readonly notification: NotificationProvider,
    private readonly translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.user.isLoggedIn()) {
      this.notification.presentToast(this.translate.instant('General.Error.SessionExpired'));
      void this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

}
