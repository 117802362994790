<div class="overlay" [ngClass]="{'active': isOverlayActive }">
  <ion-grid class="ion-no-padding" (click)="$event.stopPropagation()">
      <ion-row>
          <ion-col>
              <ng-content></ng-content>
              <h1 [style.top.px]="videoHeight/2 - 60" [ngClass]="{'animate': isAnimate }" [innerHTML]="displayText"></h1>
          </ion-col>
      </ion-row>
      <app-custom-progress-bar [style.margin-top.px]="videoHeight-20" [width]="(100-stepWidth*counter) + '%'" style="display:block;"></app-custom-progress-bar>
  </ion-grid>
</div>
