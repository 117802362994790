import { NgModule } from '@angular/core';
import { OverlayComponent } from './overlay/overlay';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { MultiStepFormComponent } from './multi-step-form/multi-step-form.component';
import { FormatTitlePipe } from '../pipes/format-title/format-title.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CustomProgressBarComponent } from './custom-progress-bar/custom-progress-bar.component';
import { FocusScrollDirective } from './scroll-focus/scroll-focus';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { ContrastSwitcherComponent } from "./contrast-switcher/contrast-switcher.component";


@NgModule({
	declarations: [
		OverlayComponent,
    MultiStepFormComponent,
    CustomProgressBarComponent,
    FormatTitlePipe,
    FocusScrollDirective,
    LanguagePickerComponent,
    ContrastSwitcherComponent
	],
  imports: [
    IonicModule,
    //IonicModule.forRoot(MyApp),
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule
  ],
  exports: [
    OverlayComponent,
    MultiStepFormComponent,
    CustomProgressBarComponent,
    FocusScrollDirective,
    LanguagePickerComponent,
    ContrastSwitcherComponent
  ]
})
export class ComponentsModule {}
