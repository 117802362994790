import { Injectable } from '@angular/core';

declare var webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root'
})
export class VoiceRecognitionService {

  recognition =  new webkitSpeechRecognition();
  isStopped = false;
  isStarted = false;
  public text = '';
  tempWords;
  target;

  constructor() { }

  init(field: string) {

    this.target = <HTMLInputElement>document.getElementById(field);

    this.recognition.interimResults = true;
    this.recognition.lang = 'de-DE';

    this.recognition.addEventListener('result', (e) => {
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join('');
      this.tempWords = transcript;
      this.target.value = transcript;
      this.target.innerHTML = transcript;
    });

    this.recognition.addEventListener('speechend', (e) => {
        this.recognition.stop();
    })

  }

  start() {
    this.isStopped = false;
    this.isStarted = true;
    this.recognition.start();
    this.recognition.addEventListener('end', ( ) => {
      if (this.isStopped) {
        this.recognition.stop();
      } else {
        this.wordConcat()
        if (!this.isStarted) {
          this.recognition.start();
        }
      }
    });
  }
  stop() {
    this.isStopped = true;
    this.isStarted = false;
    this.wordConcat()
    this.recognition.stop();
    this.text = '';
    this.tempWords = '';
  }

  wordConcat() {
    this.text = this.text + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }

  readInput(field: string) {
    let textField = <HTMLInputElement>document.getElementById(field);
    let msg = new SpeechSynthesisUtterance();
    msg.text = textField.value;
    msg.lang = 'de-DE';
    window.speechSynthesis.speak(msg);
  }

  readText(field: string) {
    let textField = <HTMLInputElement>document.getElementById('ra' + field);
    let msg = new SpeechSynthesisUtterance();
    msg.text = textField.innerHTML;
    msg.lang = 'de-DE';
    window.speechSynthesis.speak(msg);
  }

}
