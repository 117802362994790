import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Internationalization } from 'src/app/services/internationalization/internationalization';

@Component({
    selector: 'app-language-picker',
    templateUrl: './language-picker.component.html',
    styleUrls: ['./language-picker.component.scss'],
    standalone: false
})
export class LanguagePickerComponent implements OnInit {
  selected = '';
  languages: string[] = [];
  readonly languageNames = {
    'de': 'Deutsch',
    'en': 'English',
    'at': 'Österreich',
    'ja': '日本',
  }

  constructor(
    private readonly translate: TranslateService,
    private readonly internationalization: Internationalization
    ) {
    this.languages = this.translate.getLangs();
    this.selected = this.translate.currentLang;
    this.translate.onLangChange.subscribe((change)=>{
      this.selected = this.translate.currentLang;
    })
   }

  ngOnInit() {}

  async change(code: string) {
    await this.internationalization.setInternationalization(code);
  }

  getImage(code: string) {
    return `url(./assets/img/languages/${code}.svg)`
  }

  isAvailable(code: string) {
    return this.languages.indexOf(code) !== -1;
  }

  isSelected(code: string) {
    return this.selected == code;
  }

}
