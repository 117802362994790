<div class="qrcode">
  <ion-content aria-modal="true">
    <ion-grid>
      <ion-row>
        <ion-col>
          <span class="heading">
          {{ 'QrCode.Title' | translate }}<span>.</span>
          </span>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <p *ngIf="showError">{{ 'QrCode.Error.NoDevice' | translate }}</p>
          <p *ngIf="hasCamera">{{ 'QrCode.Text' | translate }}</p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <video #videoElem></video>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-button (click)="cancel()">{{ 'General.Cancel' | translate }}</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</div>
