import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'minuteSeconds',
    standalone: false
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number, ...args) {
    const minutes: number = Math.floor(value / 60);
    //@ts-ignore
    return minutes.toString().padStart(2, '0') + ':' + 
    //@ts-ignore
    Math.floor((value - minutes * 60)).toString().padStart(2, '0');
  }
}
