<ion-menu side="start" content-id="main-content" menu-id="contrast" id="contrast">
  <ion-header>
    <ion-toolbar>
      <ion-title>Kontraste</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-menu-toggle *ngFor="let c of contrastNames; trackBy: trackByFn" (click)="change(c.code)">
        <ion-item button="true">
          <ion-label>{{ c.label }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>

