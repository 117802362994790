import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-custom-progress-bar',
    templateUrl: './custom-progress-bar.component.html',
    styleUrls: ['./custom-progress-bar.component.scss'],
    standalone: false
})
export class CustomProgressBarComponent {
  @Input() public width: string;

  constructor() { }

}
