<ion-grid>
  <ion-row>
    <ion-col class="language" *ngFor="let language of languages"
    [class.selected]="isSelected(language)"
    (click)="change(language)">
      <ion-grid>
        <ion-row>
          <ion-col>
            <div class="flag" [style.background-image]="getImage(language)">
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-text-center">
            {{ languageNames[language] }}
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>