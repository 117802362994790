import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[focus-scroll]',
    standalone: false
})

export class FocusScrollDirective {

  constructor() {}

  @HostListener('ionFocus', ['$event.target'])
  onIonFocus(target) {
    target.scrollIntoView({behavior: 'smooth'});
  }

}
