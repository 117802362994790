import { Component, OnInit } from '@angular/core';
import { MenuService } from "../../services/menu/menu.service";

@Component({
  selector: 'app-contrast-switcher',
  templateUrl: './contrast-switcher.component.html',
  styleUrls: ['./contrast-switcher.component.scss'],
  standalone: false
})
export class ContrastSwitcherComponent implements OnInit {

  readonly contrastNames = [
    {
      "code": "bw-pos",
      "label": "SW Positiv"
    },
    {
      "code": "bw-neg",
      "label": "SW Negativ"
    },
    {
      "code": "by-pos",
      "label": "SG Positiv"
    },
    {
      "code": "by-neg",
      "label": "SG Negativ"
    },
    {
      "code": "original",
      "label": "Original"
    }
  ];

  public selectContrast;

  constructor(
    private menu: MenuService
  ) {
    this.selectContrast = 'original';
    this.dynamicContrast();
  }

  ngOnInit() {}

  dynamicContrast() {
    this.menu.activeContrast(this.selectContrast);
  }

  async change(code: string) {
      this.menu.activeContrast(code)
  }

  trackByFn(index: number): number {
    return index;
  }

}
