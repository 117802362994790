import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateFormatProvider {

  constructor() { }

  /**
   * Get the date in ISO-Format (YYYY-MM-DD).
   * @param date      Date as Date object or as string in German locale format (Optional, defaults to current Date)
   * @param withTime  Appends the time to the end of the string (Optional, defaults to no time)
   * @returns         Date as a string in ISO-Format
   */
  public getDateAsIsoString(date?: Date | string, withTime?: boolean): string {
    if (!date) {
      date = new Date();
    }
    let res = '';
    if (typeof date === "string") {
      res = moment(date, 'DD.MM.YYYY HH:mm:ss').toISOString(true);
    }
    else {
      res = moment(date).toISOString(true);
    }
    if (!withTime) {
      res = res.split('T')[0];
    }
    return res;
  }

  /**
   * Get the date in German locale format (D.M.YYYY).
   * @param date      Date as string in ISO-Format (Optional, defaults to current Date)
   * @param withTime  Appends the time to the end of the string (Optional, defaults to no time)
   * @returns         Date as a string in ISO-Format
   */
  public getDateInLocaleFormat(date?: string, withTime?: boolean): string {
    if (!date) {
      date = moment().toLocaleString();
    }
    if (withTime) {
      return moment(date).format('DD.MM.YYYY HH:mm:ss');
    }
    return moment(date).format('DD.MM.YYYY');
  }
}
